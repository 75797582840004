<template>
    <Layout>

        <div class="informacoes-container1">
            <div class="informacoes-container2">
                <div class="informacoes-container3">
                    <label class="informacoes-text">{{$t_('politica_do_site')}}</label>
                    <h2 class="informacoes-text01">{{$t_('informacoes')}}</h2>
                </div>
                <div class="informacoes-container4">
                    <v-list color="transparent" light dense>
                        <v-list-item v-for="(item, index) in navegacao" :key="index" @click="getLink(item)" v-once class="informacoes-text04">
                            {{ item.title }}
                        </v-list-item>
                    </v-list>
                </div>
            </div>
                <div class="informacoes-container5">
                    <h1 v-html="$config.conteudo.titulo" class="informacoes-text10"></h1>
                    <span v-html="$config.conteudo.html" class="informacoes-text11"></span> 
                </div>
        </div>

    </Layout>
</template>

<script>
    export default {
        name: 'Informacoes',
        components: {
            Layout: () => import('@/layout/Default.vue'),
        },
        data() {
            return {
                navegacao: [
                    {
                        id: 1,
                        title: this.$t_('politica_privacidade'),
                        icon: '',
                        link: 'privacidade'
                    },
                    {
                        id: 2,
                        title: this.$t_('cookies'),
                        icon: '',
                        link: 'cookies'
                    },
                    {
                        id: 3,
                        title: this.$t_('termo_uso'),
                        icon: '',
                        link: 'termo-uso'
                    },
                    {
                        id: 4,
                        title: this.$t_('termo_compra'),
                        icon: '',
                        link: 'termos-compra'
                    },
                ],
            }
        },
        methods: {
            toTop() {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            getLink(item) {
                
                if(this.$route.name == 'Politicas'){
                    this.$router.push({ params: { pagina: item.link } })
                } else {
                    this.$router.push({ path: item.link })
                }
                

                // document.location.reload()
            },
            getRouter(){
                let rota = document.location.href.indexOf('#') == -1 ? document.location.pathname.split('/')[2] : document.location.href.split('#')[1].split('/')[2];
                if(this.$config.dados.multilinguagem){
                    rota = document.location.pathname.split('/')[3];
                }

                switch(rota){
                    case ('privacidade'):               
                        this.$config.getConteudo('privacidade');
                        break;
                    case 'cookies':                  
                        this.$config.getConteudo('cookies');
                        break;
                    case ('termos-compra'):         
                        this.$config.getConteudo('termo_compra');
                        break;
                    case ('termo-uso'):         
                        this.$config.getConteudo('termo_uso');
                        break;
                    default: 
                        this.$axios.rota('Error')
                }
            }
        },
        mounted() {
            this.getRouter()
            const self = this
            setTimeout(() => {
                self.toTop();
            }, 1500)
        },
       
    }
</script>

<style scoped>
    .informacoes-container {
        width: 100%;
        height: auto;
        display: flex;
        min-height: 100vh;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .informacoes-container1 {
        flex: 0 0 auto;
        width: 970px;
        display: flex;
        /* padding: 15px; */
        padding: 32.2px;
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-start;
    }

    .informacoes-container2 {
        flex: 0 0 auto;
        width: 300PX;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .informacoes-container3 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        padding: 10px;
        align-items: flex-start;
        margin-bottom: 10px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .informacoes-text {
        color: var(--dl-color-gray-500);
        font-size: 12px;
    }

    .informacoes-text01 {
        color: var(--dl-color-gray-500);
        font-size: px;
    }

    .informacoes-container4 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        padding: 10px;
        align-items: flex-start;
        margin-bottom: 10px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .informacoes-text02 {
        color: var(--dl-color-gray-500);
        width: 100%;
        font-size: 14px;
        padding-top: 8px;
        border-color: var(--dl-color-secundaria);
        border-width: 1px;
        padding-left: 10px;
        margin-bottom: 5px;
        padding-right: 0px;
        padding-bottom: 8px;
        border-top-width: 0px;
        border-left-width: 3px;
        border-right-width: 0px;
        border-bottom-width: 0px;
    }

    .informacoes-text04 {
        color: var(--dl-color-gray-500);
        width: 100%;
        font-size: 14px;
        padding-top: 8px;
        border-color: var(--dl-color-secundaria);
        border-width: 1px;
        padding-left: 10px;
        margin-bottom: 5px;
        padding-right: 0px;
        padding-bottom: 8px;
        border-top-width: 0px;
        border-left-width: 3px;
        border-right-width: 0px;
        border-bottom-width: 0px;
    }

    .informacoes-text04 {
        color: var(--dl-color-gray-500);
        width: 100%;
        font-size: 14px;
        padding-top: 8px;
        border-color: var(--dl-color-secundaria);
        border-width: 1px;
        padding-left: 10px;
        margin-bottom: 5px;
        padding-right: 0px;
        padding-bottom: 8px;
        border-top-width: 0px;
        border-left-width: 3px;
        border-right-width: 0px;
        border-bottom-width: 0px;
    }
    .informacoes-text06 {
        color: var(--dl-color-gray-500);
        width: 100%;
        font-size: 14px;
        padding-top: 8px;
        border-color: var(--dl-color-secundaria);
        border-width: 1px;
        padding-left: 10px;
        margin-bottom: 5px;
        padding-right: 0px;
        padding-bottom: 8px;
        border-top-width: 0px;
        border-left-width: 3px;
        border-right-width: 0px;
        border-bottom-width: 0px;
    }

    .informacoes-text08 {
        color: var(--dl-color-gray-500);
        width: 100%;
        font-size: 14px;
        padding-top: 8px;
        border-color: var(--dl-color-secundaria);
        border-width: 1px;
        padding-left: 10px;
        margin-bottom: 5px;
        padding-right: 0px;
        padding-bottom: 8px;
        border-top-width: 0px;
        border-left-width: 3px;
        border-right-width: 0px;
        border-bottom-width: 0px;
    }

    .informacoes-container5 {
        flex: 0 0 auto;
        width: calc(100% - 300px);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }

    .informacoes-text10 {
        margin-bottom: 15px;
    }

    .informacoes-text11 {
        color: var(--dl-color-gray-200);
        font-size: 14px;
    }

    @media(max-width: 991px) {
        .informacoes-container1 {
            width: 100%;
        }
    }

    @media(max-width: 767px) {
        .informacoes-container2 {
            display: none;
        }

        .informacoes-container3 {
            width: 50%;
        }

        .informacoes-container4 {
            width: 100%;
        }

        .informacoes-container5 {
            width: 100%;
        }

        .informacoes-text10 {
            font-size: 22px;
            align-self: center;
        }
    }

    @media(max-width: 479px) {
        .informacoes-container3 {
            width: 100%;
        }

        .informacoes-container4 {
            width: 100%;
        }
    }
</style>